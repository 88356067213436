.chat{
  background-color: #FFF;
  width: 68px;
  height: 68px;
  border: 2px solid var(--primaria);
  border-radius: 50%;
  padding: 0px;
  position: fixed;
  z-index: 998;
  bottom: 16px;
  left: 16px;
  box-shadow: 1px 1px 10px -3px #000;
  cursor: pointer;
  em{
    width: 64px;
    border-radius: 50%;
    display: inline-block;
    overflow: hidden;
    text-align: center;
    position: relative;
    height: 64px;
    img{
      width: 100%;
    }
  }
  &-mark{
    border-radius: 20px;
    bottom: 65px;
    left: 64px;
    border: 2px solid var(--primaria);
    width: 18px;
    color: #fff;
    height: 18px;
    background-color: var(--primaria);
    position: fixed;
    text-align: center;
    line-height: 18px;
    z-index: 999;
    font-size: 12px;
    @media screen and (max-width: 600px) {
      &.top{
        bottom: 138px;
      }
    }
  }
  &.chat-close{
    background-color: #DE0059;
    width: 50px;
    height: 50px;
    z-index: 999 !important;
    img{
      width: 20px;
    }
  }
  @media screen and (max-width: 600px) {
    &.top{
      bottom: 88px;
    }
  }
}