
.footer-page {
  height: 122px;
  position: relative;
  display: table-row;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--white);
  box-shadow: 0 -4px 11px -7px rgba(76, 76, 76, 0.33);
  z-index: 2;
  @media (max-width: 600px){
    height: 182px;
  }
  .container{
    @media (min-width: 600px) {
      margin: 11px auto;
      padding-top: 0;
      padding-bottom: 27px;
      max-width: 950px;
    }
    padding: 0;
  }
  .top-footer{
    display: flex;
    justify-content: space-between;
    padding: 15px 0px;
    align-items: center;
    .logo{
      width: 145px;
      margin-left: 4px;
      svg{
        width: 145px;
        height: 30px;
        color: var(--cinza-forte);
      }
    }
    @media (max-width: 600px) {
      .logo{
        margin-left: 0;
        margin-right: 0;
        width: 160px;
      }
      flex-direction: column;
      padding: 5%;
      gap: 4px;

    }

    .paises{
      display: flex;
      gap: 15px;
      color: var(--cinza-forte);
      .active{
        font-weight: bold;
      }
      a{
        border-right: 1px solid var(--cinza-fraco);
      }
    }
  }
  .bottom-footer{
    border-top: 1px solid var(--cinza-fraco);
    display: flex;
    padding: 10px 0;
    justify-content: space-between;
    color: var(--cinza-forte);
    font-size: 1em;
    a{
        color: var(--cinza-forte);
    }
    h5{
      text-transform: uppercase;
      font-weight: bold;
    }
    .links{
      display: flex;
      gap: 50px;
    }
    .column-footer{
      display: flex;
      flex-direction: column;
      gap: 5px;
      img{
        height: 110px;
      }
    }
    @media (max-width: 600px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      margin: 0 25px;
      gap: 0 4px;
      .links{
        padding-top: 10px;
        a{
          width: 81px;
        }
      }
      .column-footer{
        width: 48%;
        margin-bottom: 10px;
        &.image{
          width: 33.333%;
          text-align: center;
          img{
            height: 100px;
          }
        }
      }
    }
  }


  &.bemingressos{
    background-color: var(--footer-background);
    .container{
      padding-bottom: 0px;
    }
    .top-footer{
      justify-content: center;
      flex-direction: column;
      gap: 6px;
      a{
        color: var(--footer-logo);
      }
      svg{
        width: 160px;
        height: 30px;
        fill: var(--footer-logo);
      }
    }
    .bottom-footer{
      display: flex;
      color: var(--footer-text);
      flex-direction: column;
      align-items: center;
      gap: 4px;
      a{
        color: var(--footer-link);
      }
    }
  }
  
}